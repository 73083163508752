import moment from 'moment'

export function authenticated (state) {
  return state.authenticated
}

export function token (state) {
  return state.token
}

export function user (state) {
  return state.user
}

export function language (state) {
  return state.language
}

export function age (state) {
  return moment().diff(moment(state.user.birthday, 'YYYYMMDD'), 'years')
}
