export function SET_AUTHENTICATED (state, value) {
  state.authenticated = value
}

export function SET_TOKEN (state, value) {
  state.token = value
}

export function SET_USER (state, value) {
  console.log('HERE', value)
  state.user = value
}

export function SET_USER_AT (state, value) {
  state.user.at = value
}

export function SET_USER_LANGUAGE (state, value) {
  state.language = value
}
