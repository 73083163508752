import { createStore } from 'vuex'
import auth from './auth'
import global from './global'

const Store = createStore({
  modules: {
    auth,
    global
  },
  strict: false
})

export default Store
