import * as VueRouter from 'vue-router'
import routes from './routes'
import Store from './store'
const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes, 
})

router.beforeEach(async (to, from) => {
  // ------------------------------------------------------------
  // check if user needs to be logged in to access this page
  // ------------------------------------------------------------
  if ('requiresAuth' in to.meta && to.meta.requiresAuth) {
    const loggedIn = await Store.dispatch('auth/me')
    if (!loggedIn) {
      console.log('?')
      return { 
        name: 'login',
        replace: true,
        query: { redirect: to.fullPath }
      }
    }
  } else {
    return true
  }
})

export default router