import { isTenant } from './helpers/general.js'

let routes = [
  {
    path: '/auth',
    component: () => import('./layout/AuthLayout.vue'),
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('./page/Auth/AuthLogin.vue')
      },
      {
        name: 'register',
        path: 'register',
        component: () => import('./page/Auth/AuthRegister.vue')
      },
      {
        name: 'forgottenPassword',
        path: 'forgotten-password',
        component: () => import('./page/Auth/AuthForgottenPassword.vue')
      },
      {
        name: 'resetPassword',
        path: 'reset-password',
        component: () => import('./page/Auth/AuthResetPassword.vue')
      }
    ]
  },
  { path: '/:pathMatch(.*)*', name: 'NotFound', template: '<div>test</div>' }
]

const centralRoutes = [
  {
    path: '/',
    component: () => import('./layout/DashboardLayout.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        name: 'dashboard',
        path: '',
        component: () => import('./page/CentralDashboard.vue')
      },
      {
        name: 'users',
        path: '/users',
        component: () => import('./page/UsersList.vue')
      },
      {
        name: 'trainings',
        path: '/trainings',
        component: () => import('./page/TrainingList.vue')
      },
      {
        name: 'groups',
        path: '/groups',
        component: () => import('./page/GroupList.vue')
      },
      {
        name: 'schemas',
        path: '/schemas',
        component: () => import('./page/SchemaList.vue')
      },
      {
        name: 'licensekeys',
        path: '/license-keys',
        component: () => import('./page/LicenseKeyList.vue')
      },
      {
        name: 'tenants',
        path: '/tenants',
        component: () => import('./page/TenantList.vue')
      }
    ]
  },
]

const tenantRoutes = [
  {
    path: '/',
    component: () => import('./layout/DashboardLayout.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        name: 'dashboard',
        path: '',
        component: () => import('./page/CentralDashboard.vue')
      },
      {
        name: 'users',
        path: '/users',
        component: () => import('./page/UsersList.vue')
      },
      {
        name: 'groups',
        path: '/groups',
        component: () => import('./page/GroupList.vue')
      },
      {
        name: 'trainings',
        path: '/trainings',
        component: () => import('./page/TrainingList.vue')
      },
      {
        name: 'schemas',
        path: '/schemas',
        component: () => import('./page/SchemaList.vue')
      },
    ]
  },
]

if (isTenant()) {
  routes = [...routes, ...tenantRoutes]
} else {
  routes = [...routes, ...centralRoutes]
}

export default routes
