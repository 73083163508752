import { createApp } from 'vue/dist/vue.esm-bundler';
import router from './router'
import Lang from 'lang.js'
import translations from '../assets/js/ll_messages'
import Store from './store'
import AWN from 'awesome-notifications'
import VueUniversalModal from 'vue-universal-modal'
import vSelect from 'vue-select'
import Highcharts from 'highcharts'
import Stock from 'highcharts/modules/stock'
import Bullet from 'highcharts/modules/bullet'
import VariablePie from 'highcharts/modules/variable-pie'
import Boost from 'highcharts/modules/boost'
import StockTools from 'highcharts/modules/stock-tools'
import DragPanes from 'highcharts/modules/drag-panes'
import AnnotationsAdvanced from 'highcharts/modules/annotations-advanced'
import Indicators from 'highcharts/indicators/indicators-all'
import HighchartsVue from 'highcharts-vue'
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import Vue3ColorPicker from 'vue3-colorpicker'
import 'vue3-colorpicker/style.css'
import pusherJs from 'pusher-js'
import axios from 'axios'

window.Pusher = pusherJs

window.axios = axios

window.axios.defaults.headers.common['Accept'] = 'application/json'

const token = localStorage.getItem('token')
if (token) {
  window.axios.defaults.headers.common.Authorization = `Bearer ${token}`
}

window.axios.interceptors.response.use(response => {
  return response
}, error => {
  return Promise.reject(error)
})

const trans = new Lang({
    messages: translations,
    locale: window.default_locale,
    fallback: window.fallback_locale
})

window.trans = trans

const notifierOptions = {
    messages: {
        async: trans.get('notify.loadingNotification'),
    },
    labels: {
        tip: trans.get('labels.tip'),
        info: trans.get('labels.info'),
        success: trans.get('labels.success'),
        warning: trans.get('labels.warning'),
        alert: trans.get('labels.alert'),
        async: trans.get('labels.async'),
    }
}

const notifier = new AWN(notifierOptions)

const app = createApp({})
app.provide('$trans', trans)
app.provide('$notify', notifier)
app.provide('tenantBrand', {
    logo: window.ecBrandLogo,
    logoOnly: window.ecBrandLogoOnly
})

app.use(Vue3ColorPicker)
app.use(router)
app.use(Store)
app.use(PerfectScrollbar)
app.use(VueUniversalModal, {
    teleportTarget: '#modals'
})
Stock(Highcharts);
Bullet(Highcharts);
VariablePie(Highcharts);
Boost(Highcharts);
DragPanes(Highcharts);
Indicators(Highcharts);
AnnotationsAdvanced(Highcharts);
StockTools(Highcharts);
app.use(HighchartsVue);

app.component('v-select', vSelect)

app.mount('#app')
