export function selectedTraining (state) {
  return state.selectedTraining
}

export function highestHr (state) {
  return state.highestHr
}

export function onlineUsers (state) {
  return state.onlineUsers
}