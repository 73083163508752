import psl from 'psl'

export const zoneColors = ['#1affff', '#9a019a', '#febbff', '#c4c4c4', 'yellow', 'green', 'blue', 'red', 'darkred']

export const checkReturnErrors = (errors) => {
  let hasError = errors?.success ?? true
  return hasError
}

export const get_progress = (ms, total) => {
  return (ms / total) * 100
}

export const ms_to_time = (duration) => {
  let seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

  hours = (hours < 10) ? "0" + hours : hours
  minutes = (minutes < 10) ? "0" + minutes : minutes
  seconds = (seconds < 10) ? "0" + seconds : seconds

  return hours + ":" + minutes + ":" + seconds
}

export const sortLocations = (data) => {
  let lastLat = 0
  let lastLong = 0
  let locations = []
  for (let i = 0; i < data.length; i++) {
    const row = data[i]
    if (lastLat !== row.location_lat && lastLong !== row.location_long) {
      lastLat = row.location_lat
      lastLong = row.location_long
      const location = { location: [row.location_lat, row.location_long], color: zoneColors[row.state - 1] }
      locations.push(location)
    }
  }
  return locations
}

export const isTenant = () => {
  const parsed = psl.parse(window.location.hostname)
  let tenant = false
  if (parsed) {
    if (parsed.subdomain !== 'www' && parsed.subdomain !== null) tenant = true
  }
  return tenant
}

export const getBroadcastTenant = () => {
  const parsed = psl.parse(window.location.hostname)
  let tenant = 'central'
  if (parsed) {
    if (parsed.subdomain !== 'www' && parsed.subdomain !== 'test' && parsed.subdomain !== null) tenant = parsed.subdomain
  }
  return tenant
}

export const hasPermission = (permissions, perm) => {
  return permissions?.find((permission) => {return permission == perm })
}

export const getZoneColors = (zone) => {
    if (zone == 1) {
      return '#1affff';
    }

    if (zone == 2) {
      return '#9a019a';
    }

    if (zone == 3) {
      return '#febbff';
    }

    if (zone == 4) {
      return '#c4c4c4';
    }

    if (zone == 5) {
      return 'green';
    }

    if (zone == 6) {
      return 'blue';
    }

    if (zone == 7) {
      return 'orange';
    }

    if (zone == 8) {
      return 'red';
    }
}

export const getZoneName = (zone) => {
    if (zone == 1) {
      return window.trans.get('zones.relax');
    }

    if (zone == 2) {
        return window.trans.get('zones.rest');
    }

    if (zone == 3) {
        return window.trans.get('zones.active');
    }

    if (zone == 4) {
        return window.trans.get('zones.warmingUp');
    }

    if (zone == 5) {
        return window.trans.get('zones.endurance1');
    }

    if (zone == 6) {
        return window.trans.get('zones.endurance2');
    }

    if (zone == 7) {
        return window.trans.get('zones.endurance3');
    }

    if (zone == 8) {
        return window.trans.get('zones.intensive');
    }
}
