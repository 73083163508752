import router from '../../router'

export async function signIn ({ dispatch, commit }, credentials) {
  try {
    await axios.get('csrf-cookie')
    const request = await axios.post('/api/login', credentials)
    const token = request.data.data.token
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    localStorage.setItem('token', token)
    commit('SET_TOKEN', token)
    return request.data
  } catch (e) {
    return Promise.reject(e.response.data)
  }
}

export async function signUp ({ dispatch, commit }, credentials) {
  try {
    const request = await axios.post('/api/register', credentials)
    const token = request.data.data.token
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    localStorage.setItem('token', token)
    commit('SET_TOKEN', token)
    return dispatch('me')
  } catch (e) {
    return Promise.reject(e.response.data)
  }
}

export async function forgottenPassword ({ commit }, credentials) {
  try {
    const request = await axios.get('/api/forgot-password',  { params: credentials })
    return request.data
  } catch (e) {
    console.log(e)
    return Promise.reject(e.response.data)
  }
}

export async function resetPassword ({ commit }, credentials) {
  try {
    const request = await axios.post('/api/reset-password', credentials)
    return request.data
  } catch (e) {
    return Promise.reject(e.response.data)
  }
}

export async function signOut ({ dispatch }) {
  await axios.get('/api/logout')
  localStorage.removeItem('token')

  return dispatch('me')
}

export async function me ({ commit }) {
  try {
    const request = await axios.get('/api/user')
    commit('SET_AUTHENTICATED', true)
    commit('SET_USER', request.data)
    commit('SET_USER_LANGUAGE', request.data.user_settings?.language ?? 'gb')
    return Promise.resolve(true)
  } catch (e) {
    console.log(e)
    commit('SET_AUTHENTICATED', false)
    commit('SET_USER', null)
    commit('SET_USER_LANGUAGE', 'en')
    commit('SET_TOKEN', null)
    router.replace({ name: 'login' })
  }
}
