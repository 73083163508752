export function SET_SELECTED_TRAINING (state, value) {
  state.selectedTraining = value
}

export function SET_HIGHEST_HR (state, value) {
  state.highestHr = value
}

export function SET_ONLINE_USERS (state, value) {
  state.onlineUsers = value
}